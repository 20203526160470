import { useEffect, useState } from "react";
import Fly from "../transitions/Fly";

export default function Surprise() {
  const [enter, setEnter] = useState(false);

  useEffect(() => {
    setEnter(true);
  }, []);

  return (
    <div className="my-14 mx-auto block w-fit">
      <Fly show={enter}>
        <img
          src="images/airplane-cropped.png"
          alt="Spanish message"
          className="w-max-sm mx-auto"
        />
      </Fly>
      <img
        src="images/Final 2nd teaser.png"
        alt="Spanish message"
        className="mx-auto my-8"
      />
      <p className="pb-5">
        Enjoyed the game? Play the original{" "}
        <a className="underline" href="https://globle-game.com">
          Globle!
        </a>
      </p>
    </div>
  );
}
