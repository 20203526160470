import { useLocalStorage } from "../hooks/useLocalStorage";
import { getPath } from "../util/svg";
import { Stats } from "../lib/localStorage";
import { today } from "../util/dates";
import { isFirefox, isMobile } from "react-device-detect";
import { useEffect, useRef, useState } from "react";
import Fade from "../transitions/Fade";

type Props = {
  setShowStats: React.Dispatch<React.SetStateAction<boolean>>;
  setScreen: React.Dispatch<React.SetStateAction<string>>;
  showLastWin?: string;
};

function SurpriseMsg({ setScreen, setShowStats, showLastWin }: Props) {
  if (showLastWin === "--") {
    return (
      <p className="mt-10 mb-4 text-center font-bold text-lg">
        Find the mystery country to reveal a surprise!
      </p>
    );
  } else {
    return (
      <div>
        <p className="font-bold text-xl text-center mt-8">
          Click the Semperis logo below to reveal a surprise!
        </p>
        <button
          className="mb-4 mt-8 mx-auto block border-[1px] p-3 rounded-lg 
        border-[#e50a7d] bg-white shadow-lg z-90 hover:scale-110 transition"
          // style={{animation}}
          onClick={() => {
            setScreen("Surprise");
            setShowStats(false);
          }}
        >
          <img
            src="images/semperis-min.png"
            alt="Semperis logo"
            className="h-20"
          />
        </button>
      </div>
    );
  }
}

export default function Statistics({ setShowStats, setScreen }: Props) {
  const firstStats = {
    gamesWon: 0,
    lastWin: new Date(0).toLocaleDateString("en-CA"),
    currentStreak: 0,
    maxStreak: 0,
    usedGuesses: [],
  };

  const [storedStats, storeStats] = useLocalStorage<Stats>(
    "statistics",
    firstStats
  );

  const { lastWin, usedGuesses } = storedStats;

  const todaysGuesses =
    lastWin === today ? usedGuesses[usedGuesses.length - 1] : "--";

  const showLastWin = lastWin >= "2022-01-01" ? lastWin : "--";

  const statsTable = [
    { label: "Date", value: showLastWin },
    { label: "Guesses", value: todaysGuesses },
  ];

  // Closing the modal
  const modalRef = useRef<HTMLDivElement>(null!);
  useEffect(() => {
    function closeModal(e: MouseEvent) {
      const target = e.target as HTMLElement;
      if (!modalRef.current?.contains(target)) {
        setShowStats(false);
      }
    }
    document.addEventListener("click", closeModal);
    return () => {
      document.removeEventListener("click", closeModal);
    };
  }, [setShowStats]);

  // Reset stats
  const [msg, setMsg] = useState({ text: "", question: false });
  const [showResetMsg, setShowResetMsg] = useState(false);
  const [resetComplete, setResetComplete] = useState(false);
  function promptReset() {
    setMsg({
      text: "Are you sure you want to reset your score?",
      question: true,
    });
    setResetComplete(false);
    setShowResetMsg(true);
  }
  function resetStats() {
    storeStats(firstStats);
    setMsg({ text: "Stats erased.", question: false });
    setResetComplete(true);
    setTimeout(() => setShowResetMsg(false), 2000);
  }
  // Clipboard
  const [showCopyMsg, setShowCopyMsg] = useState(false);
  async function copyToClipboard() {
    const shareString = `🌎 Globle: Semperis Edition 🌍

I figured out the mystery country in ${todaysGuesses} guess${
      todaysGuesses === 1 ? "" : "es"
    }!

https://globle-semperis.com`;
    if ("canShare" in navigator && isMobile && !isFirefox) {
      return await navigator.share({
        title: "Globle Stats",
        text: shareString,
      });
    } else {
      setMsg({ text: "Copied to clipboard!", question: false });
      setShowCopyMsg(true);
      setTimeout(() => setShowCopyMsg(false), 2000);
      if ("clipboard" in navigator) {
        return await navigator.clipboard.writeText(shareString);
      } else {
        return document.execCommand("copy", true, shareString);
      }
    }
  }

  return (
    <div ref={modalRef}>
      {" "}
      <button
        className="absolute top-3 right-4"
        onClick={() => setShowStats(false)}
      >
        <svg
          x="0px"
          y="0px"
          viewBox="0 0 460.775 460.775"
          width="12px"
          className=" dark:fill-gray-300"
        >
          <path d={getPath("x")} />
        </svg>
      </button>
      <SurpriseMsg
        setShowStats={setShowStats}
        setScreen={setScreen}
        showLastWin={showLastWin}
      />
      <table cellPadding="4rem" className="mx-auto" width="100%">
        <tbody>
          {statsTable.map((row, idx) => {
            return (
              <tr key={idx}>
                <td className="pt-4 border-b-2 border-dotted border-slate-700 text-lg font-medium">
                  {row.label}
                </td>
                <td className="pt-4 border-b-2 border-dotted border-slate-700 text-lg font-medium">
                  {row.value}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="py-6 flex">
        <button
          className="bg-red-700 text-white rounded-md px-6 py-2 block 
          text-base font-medium hover:bg-red-900 
          focus:outline-none focus:ring-2 focus:ring-red-300 mx-4"
          onClick={promptReset}
        >
          Reset
        </button>
        <button
          className="bg-blue-700 hover:bg-blue-900 dark:bg-purple-800 dark:hover:bg-purple-900
          text-white rounded-md px-8 py-2 block text-base font-medium 
          focus:outline-none focus:ring-2 focus:ring-blue-300 flex-grow mx-10"
          onClick={copyToClipboard}
        >
          Share
        </button>
      </div>
      <Fade
        show={showResetMsg}
        background="border-4 border-sky-300 dark:border-slate-700 bg-sky-100 dark:bg-slate-900 drop-shadow-xl 
        absolute z-10 top-32 w-fit inset-x-0 mx-auto py-6 px-6 rounded-md space-y-2"
      >
        <p className="text-gray-900 dark:text-gray-300  ">{msg.text}</p>
        {msg.question && (
          <div className="py-6 flex justify-center space-x-8">
            <button
              className="bg-red-700 text-white rounded-md px-6 py-2 block text-base font-medium hover:bg-red-900 disabled:bg-red-900 focus:outline-none focus:ring-2 focus:ring-red-300"
              onClick={resetStats}
              disabled={resetComplete}
            >
              Yes
            </button>
            <button
              className="bg-blue-700 text-white rounded-md px-6 py-2 block text-base font-medium hover:bg-blue-900 disabled:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-300"
              onClick={() => setShowResetMsg(false)}
              disabled={resetComplete}
            >
              No
            </button>
          </div>
        )}
      </Fade>
      <Fade
        show={showCopyMsg}
        background="border-4 border-sky-300 dark:border-slate-700 drop-shadow-xl 
      absolute z-10 top-32 w-fit inset-x-0 mx-auto py-6 px-6 rounded-md space-y-2"
      >
        <p className="text-gray-900 dark:text-gray-300">{msg.text}</p>
      </Fade>
    </div>
  );
}
