import { useEffect, useState } from "react";

type Props = {
  show: boolean;
  children: any;
  background?: string;
};

export default function Fly({ show, children, background }: Props) {
  const [appear, setAppear] = useState(false);

  useEffect(() => {
    if (show) {
      setTimeout(() => setAppear(true), 150);
    }
    if (!show) {
      setAppear(false);
    }
  }, [show]);

  return (
    <div
      style={{
        transition: "all 1000ms ease-out",
        zIndex: 100,
        transform: appear
          ? "translateX(0) translateY(0)"
          : "translateX(-100vw) translateY(100%)",
      }}
      className={background}
    >
      {children}
    </div>
  );
}
