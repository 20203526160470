import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../context/ThemeContext";

type Props = {
  show: boolean;
  children: any;
  background?: string;
};

export default function Fade({ show, children, background }: Props) {
  const [appear, setAppear] = useState(false);
  const [exist, setExist] = useState(false);

  // Backgorund style
  const { nightMode } = useContext(ThemeContext).theme;
  const bgColours = nightMode
    ? `radial-gradient(ellipse at top, rgba(22, 1, 82, 0.4), transparent), 
  radial-gradient(ellipse at bottom, rgba(125, 48, 116, 0.2), transparent) 
  no-repeat fixed black`
    : `radial-gradient(ellipse at top, rgba(63, 201, 255, 0.2), transparent), 
    radial-gradient(ellipse at bottom, rgba(255, 196, 87, 0.2), transparent) 
    no-repeat fixed white`;

  useEffect(() => {
    if (show) {
      setExist(true);
      setTimeout(() => setAppear(true), 150);
    }
    if (!show) {
      setAppear(false);
      setTimeout(() => setExist(false), 150);
    }
  }, [show]);

  return exist ? (
    <div
      style={{
        transition: "all 250ms linear",
        zIndex: 100,
        opacity: appear ? "1" : "0",
        background: bgColours,
      }}
      className={background}
    >
      {children}
    </div>
  ) : null;
}
